.datePicker {
  width: 100%;
}

.react-datepicker__input-container {
  font-size: 14px;
  width: 100%;
  border: 1px solid #D5D5D5;
  border-radius: 5px;
  padding: 4px
}
